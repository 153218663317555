import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import { usePhotoStore } from '../stores/photo'

import FindVoxy from '../components/FindVoxy.vue'
import Tag from '../components/Photo/Tag.vue'

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import { Fancybox } from "@fancyapps/ui";

import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Toggle class collapse photos list
var photosCollapsible = document.getElementById('photos');
if (typeof(photosCollapsible) != 'undefined' && photosCollapsible != null){
    photosCollapsible.addEventListener('hide.bs.collapse', function (e) {
        document.querySelector('#' + e.target.id).closest(".card").classList.remove('open');
    });

    photosCollapsible.addEventListener('show.bs.collapse', function (e) {
        document.querySelectorAll('#photos .card').forEach((card) => {
            card.classList.remove('show');
        });

        document.querySelector('#' + e.target.id).closest(".card").classList.add('open');
    });
}

// Photos detail page masonry and fancybox
var grid = document.querySelector('.grid');

if (typeof(grid) != 'undefined' && grid != null) {
    var masonry = new Masonry( grid, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        gutter: 10
    });

    imagesLoaded( grid ).on( 'progress', function() {
        // layout Masonry after each image loads
        masonry.layout();
    });

    const options = {
        trapFocus: false,
        Toolbar: {
            items: {
                tagging: {
                    tpl: `<button class="f-button f-button-tag"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512" fill="#fff"><path d="M128 128a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0A128 128 0 1 0 96 128a128 128 0 1 0 256 0zM32 480c1.2-79.7 66.2-144 146.3-144h91.4c17.7 0 34.6 3.1 50.3 8.9l0-.7v-33c-15.9-4.7-32.8-7.2-50.3-7.2H178.3C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c10 0 18.8-4.9 24.2-12.5l-26.8-26.8c.2 2.4 .2 4.8 .3 7.2H32zm513.9 11.3l73.4-73.4c18.7-18.7 18.7-49.1 0-67.9L512 242.7c-12-12-28.3-18.7-45.3-18.7H384c-17.7 0-32 14.3-32 32v82.7c0 17 6.7 33.3 18.7 45.3L478.1 491.3c18.7 18.7 49.1 18.7 67.9 0zM384 256h82.7c8.5 0 16.6 3.4 22.6 9.4L596.7 372.7c6.2 6.2 6.2 16.4 0 22.6l-73.4 73.4c-6.2 6.2-16.4 6.2-22.6 0L393.4 361.4c-6-6-9.4-14.1-9.4-22.6V256zm56 40a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"/></svg></button>`,
                    click: () => {
                        const photoStore = usePhotoStore()
                        photoStore.toggleDrawer(true)
                    }
                }
            },
            display: {
                left: [
                    "infobar",
                ],
                middle: [
                    "zoomIn",
                    "zoomOut",
                    "rotateCCW",
                    "rotateCW",
                ],
                right: [
                    "slideshow",
                    "tagging",
                    "fullscreen",
                    "download",
                    "thumbs",
                    "close",
                ]
            }
        },
        on: {
            loading: (fancybox, slide) => {
                const photoStore = usePhotoStore()
                photoStore.setPhotoIdSelected(fancybox.getSlide()?.photoId)
            },
        }
    }

    Fancybox.bind('[data-fancybox="gallery"]', options);
}

// Voxy element
if (document.getElementById("findVoxy")){
    const app1 = createApp({
        render() {
          return h(FindVoxy)
        }
    })
    
    app1.mount('#findVoxy')
}

if (document.getElementById("photoTag")){
    const app2 = createApp({
        render() {
            return h(Tag, convertDataSet('photoTag'))
        }
    })

    app2.mount('#photoTag')
}