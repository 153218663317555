<template>
    <el-dialog
        :model-value="showProductModal"
        :before-close="handleClose"
        :append-to-body="true"
        @open="onOpen()"
        class="productModal"
        width="800px"
    >
        <div class="row">
            <div class="col-md-4 d-flex align-items-center d-none d-md-flex">
                <img :src="product.thumbnail" :alt="'Afbeelding ' + product.name">
            </div>
            <div class="col-md-8 content">
                <el-form :model="form" ref="productBuyFormRef" @submit.prevent="submitForm(productBuyFormRef)">
                    <h2 class="title">{{product.name}}</h2>
                    <div class="stats">
                        <p class="price">{{formatCurrency(product.price)}}</p>
                        <div class="stars">
                            <i class="fa-solid fa-star star"></i>
                            <i class="fa-solid fa-star star"></i>
                            <i class="fa-solid fa-star star"></i>
                            <i class="fa-solid fa-star star"></i>
                            <i class="fa-regular fa-star-half-stroke star"></i>
                        </div>
                    </div>
                    <p class="stock-info" v-if="product.stockQuantity > 0">Op voorraad: {{ product.stockQuantity }}</p>
                    <p class="stock-info text-danger" v-else>Geen product op voorraad</p>
                    <div class="description" v-html="product.description"></div>

                    <div class="options" v-if="product.options">
                        <div class="option" v-for="option in product.options" v-bind:key="option.id">
                            <el-form-item
                                :prop="'option-' + option.id"
                                :rules="[
                                    { required: true, message: 'Selecteer een waarde', trigger: 'change' },
                                ]"
                                :show-message="showErrors"
                            >
                            <h4 class="option-title">
                                <span>{{option.name}}</span>
                                <el-popover
                                    v-if="option.description"
                                    placement="top-start"
                                    trigger="click"
                                    width="220"
                                    :content="option.description"
                                >
                                <template #reference>
                                    <i class="fa-solid fa-circle-question extra-info-popover"></i>
                                </template>
                                </el-popover>
                                <span class="remove-selection" @click="removeSelection('option-'+option.id)" v-if="(Object.keys(form).includes('option-'+option.id))">Verwijder selectie</span>
                            </h4>
                            <legend class="visually-hidden">Kies een {{option.name}}</legend>
                            <el-radio-group class="option-inputs" v-model="form['option-'+option.id]">
                                <el-radio-button
                                    v-for="value in option.values"
                                    v-bind:key="value.product_option_id + '-' + value.id"
                                    :name="'value-' + value.id"
                                    :label="value.name"
                                    :disabled="!(inStockItems.includes(value.id))">
                                    {{value.name}}
                                    <svg v-if="!(inStockItems.includes(value.id))">
                                        <line x1='0' y1='0' x2='100%' y2='100%' />
                                    </svg>
                                </el-radio-button>
                            </el-radio-group>
                            </el-form-item>
                        </div>
                    </div>
                    
                    <el-form-item>
                        <el-button class="add-to-cart" :class="{disabled : product.stockQuantity <= 0 }" @click="submitForm(productBuyFormRef)" :disabled="product.stockQuantity <= 0">Toevoegen aan winkelmandje</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type { FormInstance } from 'element-plus'
import { useCartStore } from '../../stores/cart'
import http from "../../libs/axios"
import _ from 'lodash'

interface Product {
    id: number,
    name: string,
    description: string,
    thumbnail: string,
    price: number,
    stockQuantity: number,
    stock: Object
}

const cartStore = useCartStore()

const props = defineProps({
    'productId': Number,
    'showProductModal': Boolean
})
const emit = defineEmits(['update:showProductModal'])

const form = ref({})
const product = ref(<Product>{})
const productBuyFormRef = ref<FormInstance>()
const showErrors = ref(false)

const inStockItems = computed(() => {
    let variantCombiLength = 0
    let items: Array<number> = []
    let selectedVariantArr: Array<string> = []
    let selectedVariantCount: number = 0
    let variantCombinations: Array<string> = []
    let formattedInputs = getInputValues(form.value)

    if (Object.keys(formattedInputs).length > 0) {
        let sortedInputs = _.orderBy(formattedInputs, 'id')

        sortedInputs.forEach((val) => {
            selectedVariantArr.push(val.selected_value.id)
        });

        selectedVariantCount = selectedVariantArr.length
    }

    variantCombinations = combinations(selectedVariantArr)

    if (product.value.stock) {
        product.value.stock.forEach((val) => {
            if (val.quantity <= 0)
                return
            
            let slugCombinations = combinations(val.variant_slug.split('_'))

            variantCombinations.forEach((var_combi) => {
                variantCombiLength = 0

                if (var_combi != '') {
                    variantCombiLength = var_combi.split('_').length
                }

                if (slugCombinations.includes(var_combi) && variantCombiLength >= (selectedVariantCount)) {
                    items.push(...val.variant_slug.split('_'))
                }
            })
        })
    }

    items = _.map(items, (n) => {
        return parseInt(n)
    });

    items = _.uniq(items)

    return items
})

async function onOpen() {
    if (!props.productId)
        return

    await http.get('/api/producten/'+props.productId)
        .then(response => {
            product.value = response
        })
        .catch(error => {
            console.log(error)
        })
}

function formatCurrency(amount: number) {
    return new Intl.NumberFormat('nl-NL', {style: 'currency',currency: 'EUR'}).format(amount)
}

function handleClose() {
    form.value = {}
    emit('update:showProductModal', false)
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    showErrors.value = true
    await formEl.validate((valid, fields) => {      
        if (!valid)
            return false

            let item = {
                'product_id': product.value.id,
                'name': product.value.name,
                'thumbnail': product.value.thumbnail,
                'price': product.value.price,
                'options': getInputValues(form.value)
            }

            cartStore.addItemToCart(item)

            handleClose()

            ElMessage({
                title: 'Product toegevoegd aan winkelwagen',
                message: 'Jouw winkelwagen is nu bijgewerkt.',
                type: 'success'
            })
    })
}

function getInputValues(input: Object) {
    let result: Array<Object> = []
    let optionObj = {}
    let valueObj = {}

    Object.keys(input).forEach((key) => {
        let optionId = parseInt(key.replace('option-', ''))
        optionObj = _.find(product.value.options, el => el.id === optionId)
        valueObj = _.find(optionObj.values, el => el.name === input[key])
        
        let itemObj = {
            'id': optionObj.id,
            'name': optionObj.name,
            'selected_value': {
                'id': valueObj.id,
                'name': valueObj.name
            }
        }

        result.push(itemObj);
    });

    return result;
}

function combinations(chars: Array<string>) {
    var string = '';
    var result = [];
    var f = function(prefix: string, chars: Array<string>) {
        for (var i = 0; i < chars.length; i++) {
            string = prefix + '_' + chars[i];
        
            if( string.charAt(0) === '_')
                string = string.slice(1);

            result.push(string);

            f(string, chars.slice(i + 1));
        }
    }
    f('', chars);

    result.push('');

    return result;
}

function removeSelection(key: string) {
    form.value = _.omit(form.value, [key]);
}
    
</script>