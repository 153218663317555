import { defineStore } from 'pinia'

interface State {
    first_name: string,
    middle_name: string | null,
    last_name: string,
    email: string,
    phone: number | null,
    birthdate: number | null,
    residence: string,
    study: string,
    institution: string,
    study_site: string,
    study_start: number | null,
    profile_pic: string,
    password: string,
    password_confirmation: string,
    terms_of_use: boolean
}

export const useRegisterStore = defineStore('register', {
    state: (): State => {
        return {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone: null,
            birthdate: null,
            residence: '',
            study: '',
            institution: '',
            study_site: '',
            study_start: null,
            profile_pic: '',
            password: '',
            password_confirmation: '',
            terms_of_use: false
        }
    },

    actions: {
        setProfilePicture(profilePic: string) {
            this.profile_pic = profilePic
        }
    },
})