<template>
    <el-dialog id="modalPaymentEvent" title="Betalen" :model-value="dialogPayment" :before-close="handleClose" @open="handleOpen"
        :append-to-body="true" width="450px">
        <p class="text">{{ props.paymentDescription }}</p>

        <!-- Styled Payment Method Selection -->
        <div v-if="walletEnabled" class="payment-method-selection">
            <div class="payment-method" :class="{ 'selected': paymentMethod === 'wallet' }"
                @click="paymentMethod = 'wallet'">
                <i class="fa-regular fa-wallet fa-2x m-2"></i>
                <h6>Saldo</h6>
                <p><i>&euro; {{ props.transactionCosts }} korting!</i></p>
            </div>
            <div class="payment-method" :class="{ 'selected': paymentMethod === 'ideal' }" @click="paymentMethod = 'ideal'">
                <i class="fa-brands fa-ideal fa-2x m-2"></i>
                <h6>iDeal</h6>
            </div>
        </div>

        <p class="text" v-if="paymentMethod === 'wallet'">Jouw saldo is momenteel <b>{{ userBalance && formatCurrency(userBalance) }}</b></p>


        <el-select v-if="paymentMethod === 'ideal'" v-model="issuer" placeholder="Selecteer bank" class="issuers-select">
            <el-option v-for="item in issuers" :key="item.id" :label="item.name" :value="item.id">
                <el-row :gutter="10">
                    <el-col :span="5"><img :src="item.image" alt="issuer"></el-col>
                    <el-col :span="19"><span>{{ item.name }}</span></el-col>
                </el-row>
            </el-option>
        </el-select>

        <div v-if="!walletEnabled" class="mt-4 saldo-tip">
            <b>Tip:</b> Maak gebruik van saldo om &euro; {{props.transactionCosts}} korting op iedere betaling te krijgen!
        </div>

        <template #footer>
            <div class="row">
                <div class="col-6">
                    <button class="btn btn-blue" @click="pay()"
                        v-bind:class="{ disabled: paymentMethod === 'ideal' && !issuer }" type="button"
                        title="Betalen">Betalen</button>
                </div>
                <div class="col-6">
                    <button @click="handleClose()" class="btn btn-grey" type="button">Annuleren</button>
                </div>
            </div>
        </template>
    </el-dialog>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import http from 'axios'

interface Issuer {
    id: number,
    name: string,
    image: string
}

const props = defineProps({
    'paymentDescription': String,
    'dialogPayment': Boolean,
    'walletEnabled': Boolean,
    'userBalance': Number,
    'transactionCosts': Number,
})

const emit = defineEmits(['update:dialogPayment', 'executePayment'])
const paymentMethod = ref('wallet') // Default to 'wallet' if event.use_wallet is true
const issuer = ref()
const issuers: Ref<Array<Issuer>> = ref([])

onMounted(() => {
    getIssuers()
})

const getIssuers = () => {
    http.get('/api/agenda/betaalproviders'
    )
        .then(function (response: any) {
            issuers.value = response
        })
        .catch(function () {
            return false
        })
}

function pay() {
    emit('executePayment', paymentMethod.value, issuer.value)
    handleClose()
}

function formatCurrency(amount: number) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount)
}

function handleOpen() {
    if (!props.walletEnabled) {
        paymentMethod.value = 'ideal' // Default to iDeal if use_wallet is false
    } else {
        paymentMethod.value = 'wallet'
    }
}

function handleClose() {
    emit('update:dialogPayment', false)
}

</script>

<style scoped>
.payment-method-selection {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 20px;
    clear: both;
}

.payment-method {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #dcdcdc;
    padding: 10px 40px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100px;
}

.payment-method.selected {
    border-color: #409eff;
    background-color: #f0f9ff;
}

.payment-method p {
    margin: 0;
    font-size: 14px;
}

.saldo-tip{
    background-color: #e2f7ff;
    padding: 10px;
    border-radius: 20px;
}
</style>
