<template>
    <el-dialog
        id="modalRegisterEvent"
        title="Inschrijven" 
        :model-value="dialogRegister"
        :before-close="handleClose"
        :append-to-body="true"
        width="40%">
        <div class="modal-body" v-if="event">
            <div>
                <p>Ik schrijf me in voor <b>{{event.name}}</b> op {{formatUnixDate(event.start_event, 'DD MMMM YYYY')}}</p>
            </div>

            <div class="form" v-if="event.form || (event.open_guests  && !userLoggedIn)">
                <el-form
                    label-position="left"
                    label-width="130px"
                    :model="data.answers"
                    ref="eventRegisterFormRef"
                >
                    <template v-if="event.open_guests && !userLoggedIn">
                        <el-form-item 
                            label="Voornaam" 
                            prop="first_name"
                            :rules="[
                                { required: true, message: 'Vul een voornaam in', trigger: 'change' },
                            ]" 
                        >
                            <el-input placeholder="Dit is een verplicht veld" v-model="data.answers['first_name']"></el-input>
                        </el-form-item>
                        <el-form-item 
                            label="Achternaam"
                            prop="last_name"
                            :rules="[
                                { required: true, message: 'Vul een achternaam in', trigger: 'change' },
                            ]" 
                        >
                            <el-input placeholder="Dit is een verplicht veld" v-model="data.answers['last_name']"></el-input>
                        </el-form-item>

                        <el-form-item 
                            label="E-mailadres"
                            prop="email"
                            :rules="[
                                { required: true, message: 'Vul een e-mailadres in', trigger: 'change' },
                                { type: 'email', message: 'Vul een correct e-mailadres in', trigger: ['blur', 'change'] },
                            ]"
                        >
                            <el-input placeholder="Dit is een verplicht veld" v-model="data.answers['email']"></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="event.form">
                        <div class="question" v-for="question in event.form.questions" v-bind:key="question.id">
                            <el-form-item
                                :prop="'question_' + question.id"
                                :rules="[
                                    { required: question.required, message: 'Selecteer een waarde', trigger: 'change' },
                                ]"
                                :show-message="showErrors"
                            >
                                <h4 class="question-title">
                                    <span>{{question.name}}</span>
                                    <el-popover
                                        v-if="question.description"
                                        placement="top-start"
                                        trigger="click"
                                        width="220"
                                        :content="question.description"
                                    >
                                        <template #reference>
                                            <i class="fa-solid fa-circle-question extra-info-popover"></i>
                                        </template>
                                    </el-popover>
                                </h4>

                                <legend class="visually-hidden">{{question.name}}</legend>

                                <el-radio-group class="question-inputs" v-model="data.answers['question_'+question.id]" v-if="question.type == 'radio' || question.type == 'radio_price'">
                                    <el-radio
                                        v-for="(value, index) in question.options"
                                        v-bind:key="question.id + '-' + index"
                                        :name="'value-' + question.id + '-' + index"
                                        :label="'value-' + index">
                                        {{value.name}} <template v-if="question.type == 'radio_price'">(&euro;{{value.price}})</template>
                                    </el-radio>
                                </el-radio-group>

                                <el-input v-model="data.answers['question_'+question.id]" v-if="question.type == 'text'"></el-input>

                                <el-checkbox-group class="question-inputs" v-model="data.answers['question_'+question.id]" v-if="question.type == 'checkbox'">
                                    <el-checkbox
                                        v-for="(value, index) in question.options"
                                        v-bind:key="question.id + '-' + index"
                                        :name="'value-' + question.id + '-' + index"
                                        :label="'value-' + index">
                                        {{value.name}}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                    </template>
                </el-form>
            </div>

            <div class="row buttons">
                <div class="col-md-6">
                    <el-button class="btn btn-blue" @click="submitForm(eventRegisterFormRef)" :disabled="loadingRegistration">Inschrijven</el-button>
                </div>
                <div class="col-md-6">
                    <el-button class="btn btn-grey" @click="handleClose">Annuleren</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import type { FormInstance } from 'element-plus'
//@ts-ignore
import { formatUnixDate } from "@F/js/helpers.ts"
import _ from 'lodash'
import http from '../../libs/axios'

const props = defineProps({
    'event': Object,
    'dialogRegister': Boolean,
    'userLoggedIn': Boolean
})

const emit = defineEmits(['update:dialogRegister', 'guest-registered', 'user-registered'])

const loadingRegistration = ref(false)
const eventRegisterFormRef = ref<FormInstance>()
const data = reactive({answers: []})
const showErrors = ref(true)
    
onMounted(() => {
    _.forEach(props.event?.form?.questions, (question) => {
        if (question.type == 'checkbox') {
            data.answers['question_' + question.id] = []
        }
    });

    if (props.event?.open_guests && !props.userLoggedIn) {
        data.answers['first_name'] = ''
        data.answers['last_name'] = ''
        data.answers['email'] = ''
    }
})

function submitForm(formEl: FormInstance | undefined) {
    loadingRegistration.value = true
    if (formEl) {
        formEl.validate((valid) => {
            if (!valid) {
                loadingRegistration.value = false
                return false
            }
                        
            registerEvent(data.answers)
            loadingRegistration.value = false
        });
    } else {
        registerEvent()
        loadingRegistration.value = false
    }
}

function registerEvent(formData?: Object) {
    let requestData = {event_id: props.event?.id}

    if (formData) {
        Object.assign(requestData, {form_data: {...formData}})
    }

    http.post('/api/agenda/inschrijven', requestData)
    .then(() => {
        ElNotification({
            title: 'Ingeschreven',
            message: 'Je bent ingeschreven.',
            duration: 60000,
            type: 'success'
        })
        
        if (props.event?.open_guests && !props.userLoggedIn) {
            emit('guest-registered')
        } else {
            emit('user-registered')
        }

        handleClose()
    })
    .catch(() => {return false})
}

function handleClose() {
    data.answers = []
    emit('update:dialogRegister', false)
}

</script>
