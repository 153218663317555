var myCollapsibleEl = document.getElementById('navbar')
myCollapsibleEl.addEventListener('hide.bs.collapse', function () {
    myCollapsibleEl.classList.add('closing')
});

myCollapsibleEl.addEventListener('hidden.bs.collapse', function () {
    myCollapsibleEl.classList.remove('closing')
});


if (window.innerWidth < 992) {
    var navLinks = document.querySelectorAll('.nav-item.has-dropdown .nav-link');

    navLinks.forEach(function(navLink) {
        navLink.addEventListener('click', function(e) {
            e.preventDefault();
            
            var parent = this.parentElement;
            var parentOpen = parent.classList.contains('submenu-open');
            var submenu = parent.querySelector('.dropdown-menu');
            var countNavItemsSubmenu = submenu.querySelectorAll('.nav-item').length;
            
            parent.classList.toggle('submenu-open');

            if (!parentOpen) {
                submenu.style.height = (countNavItemsSubmenu * 33.59) + 'px';
                submenu.style.opacity = '1';
            } else {
                submenu.style.height = '0';
                submenu.style.opacity = '0';
            }
        });
    });
}

const videoHomepage = document.querySelector(".video-tab video")
const homeTabButton = document.getElementById("home-tab")

if (videoHomepage) {
    homeTabButton.addEventListener('click', function(e) {
        videoHomepage.load();
    })
}

if (document.getElementById('recaptcha_registration') !== null) {
    const getFooter = document.getElementsByTagName('footer')[0];
    var s = document.createElement("script");
    s.type = "application/javascript";
    s.async = true;
    s.defer = true;
    s.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=nl";
    getFooter.append(s);

    const onloadCallback = function () {
        grecaptcha.render('recaptcha_registration', {
            'sitekey': '6Lc8wbEUAAAAAKcYW4m8WvC_inXIl3G2mnFJTQmo'
        });
    };

    window.onloadCallback = onloadCallback;
}