<template>
    <div class="form-step">
        <div class="row">
            <div class="col-xl-5 col-lg-4">
                <el-form-item label="Voornaam" prop="first_name">
                    <el-input 
                        v-model="first_name" 
                        placeholder="Dit is een verplicht veld"
                    ></el-input>
                </el-form-item>
            </div>

            <div class="col-xl-2 col-lg-4">
                <el-form-item label="Tussenvoegsel" prop="middle_name">
                    <el-input v-model="middle_name"></el-input>
                </el-form-item>
            </div>

            <div class="col-xl-5 col-lg-4">
                <el-form-item label="Achternaam" prop="last_name">
                    <el-input v-model="last_name" placeholder="Dit is een verplicht veld"></el-input>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <el-form-item label="E-mailadres" prop="email">
                    <el-input v-model="email" placeholder="Dit is een verplicht veld"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-6">
                <el-form-item label="Telefoonnummer" prop="phone" class="with-prepend">
                    <el-input v-model.number="phone" placeholder="12345678">
                        <template #prepend>06-</template>
                    </el-input>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <el-form-item label="Geboortedatum" prop="birthdate">
                    <el-date-picker 
                        v-model="birthdateComp"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="x"
                        placeholder="Dit is een verplicht veld"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>

            <div class="col-md-6">
                <el-form-item label="Woonplaats" prop="residence">
                    <el-autocomplete
                        v-model="residence"
                        placeholder="Dit is een verplicht veld"
                        :fetch-suggestions="queryResidences"
                        :trigger-on-focus="false"
                    ></el-autocomplete>
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed }  from 'vue'
import { useRegisterStore } from '../../stores/register'
import { storeToRefs } from 'pinia'
import http from "../../libs/axios"

const registerStore = useRegisterStore()
const { first_name, middle_name, last_name, email, phone, birthdate, residence } = storeToRefs(registerStore)

const birthdateComp = computed({
    get() {
        if (birthdate.value)
            return birthdate.value * 1000
    },
    set( newValue ) {
        if (newValue)
            birthdate.value = newValue / 1000
    }
})

const queryResidences = (queryString: string, cb: (arg: any) => void) => {
    http.post('/api/register/querySuggestions/residences', {'query': queryString})
    .then(response => {
        cb(response);
    })
    .catch(() => {cb([])});
}
</script>