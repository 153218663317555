if (document.getElementById('showAllRegistrations')) {
    document.getElementById('showAllRegistrations').addEventListener('click', function(e) {
        e.preventDefault();
        var locationWrapper = document.querySelector('.location-wrapper');
        var registrationsWrapper = document.querySelector('.all-registrations-wrapper');

        locationWrapper.classList.add('collapsed');
        registrationsWrapper.classList.remove('collapsed');
    });

    document.getElementById('hideAllRegistrations').addEventListener('click', function(e) {
        e.preventDefault();
        var locationWrapper = document.querySelector('.location-wrapper');
        var registrationsWrapper = document.querySelector('.all-registrations-wrapper');

        locationWrapper.classList.remove('collapsed');
        registrationsWrapper.classList.add('collapsed');
    });
}

document.querySelectorAll('.committees-block .committee .read-more').forEach(function (element) {
    element.addEventListener('click', function (event) {
        event.preventDefault();

        var myClass = element.getAttribute('data-cie-id');

        document.querySelector('.info-items').style.display = 'none';
        document.querySelector('.full-text#committee-' + myClass).style.display = 'block';
    });
});

document.querySelectorAll('.committees-block .full-text .back-to-overview').forEach(function (element) {
    element.addEventListener('click', function(event) {
        event.preventDefault();

        document.querySelectorAll('.committees-block .full-text').forEach(function (element) {
            element.style.display = 'none';
        });

        document.querySelector('.info-items').style.display = 'block';
    });
});