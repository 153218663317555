<template>
    <div class="filter-group">
        <h5 class="filter-group-heading">Snel zoeken</h5>
        <div class="filter-item">
            <el-input type="text" id="filter-name" v-model="filterInputs.name" placeholder="Zoek op naam" @input="textFilterChanged"></el-input>
        </div>
    </div>

    <div class="filter-group">
        <h5 class="filter-group-heading">Woonplaats</h5>

        <div class="filter-item">
            <el-select
                v-model="filterInputs.residence"
                multiple
                placeholder="Filter op woonplaats"
                style="width: 100%"
                @change="filterChanged"
                filterable
                popper-class="filter-dropdown"
                :teleported="false"
                >
                <el-option
                    v-for="option in filterOptions.residences"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                >
                    <span class="filter-text">{{ option.label }}</span>
                    <span class="filter-count">{{ option.count }}</span>
                </el-option>
            </el-select>
        </div>
    </div>

    <div class="filter-group">
        <h5 class="filter-group-heading">Studie</h5>

        <div class="filter-item">
            <label for="filter-study-site">Studie</label>
            <el-select
                v-model="filterInputs.study"
                multiple
                placeholder="Filter op studie"
                style="width: 100%"
                @change="filterChanged"
                filterable
                popper-class="filter-dropdown"
                :teleported="false"
                >
                <el-option
                    v-for="option in filterOptions.studies"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                >
                    <span class="filter-text">{{ option.label }}</span>
                    <span class="filter-count">{{ option.count }}</span>
                </el-option>
            </el-select>
        </div>
        
        <div class="filter-item">
            <label for="filter-study-site">Studie plaats</label>
            <el-select
                v-model="filterInputs.study_site"
                multiple
                placeholder="Filter op studie plaats"
                style="width: 100%"
                @change="filterChanged"
                filterable
                popper-class="filter-dropdown"
                :teleported="false"
                >
                <el-option
                    v-for="option in filterOptions.study_sites"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                >
                    <span class="filter-text">{{ option.label }}</span>
                    <span class="filter-count">{{ option.count }}</span>
                </el-option>
            </el-select>
        </div>

        <div class="filter-item">
            <label for="filter-study-site">Studie instelling</label>
            <el-select
                v-model="filterInputs.study_institution"
                multiple
                placeholder="Filter op studie instelling"
                style="width: 100%"
                @change="filterChanged"
                filterable
                popper-class="filter-dropdown"
                :teleported="false"
                >
                <el-option
                    v-for="option in filterOptions.study_institutions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                >
                    <span class="filter-text">{{ option.label }}</span>
                    <span class="filter-count">{{ option.count }}</span>
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import _ from 'lodash'

const props = defineProps(['filters', 'filterOptions'])
const emit = defineEmits(['update:filters', 'filterUpdate'])

const filterInputs = computed(() => {
    return props.filters
})

const textFilterChanged = _.debounce(() => {
    emit('update:filters', filterInputs)
    emit('filterUpdate')
}, 500);

function filterChanged() {
    emit('update:filters', filterInputs)
    emit('filterUpdate')
}

</script>