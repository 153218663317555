import { defineStore } from 'pinia'

interface State {
    open: boolean,
    selected_photo_id: number | null,
}

export const usePhotoStore = defineStore('photo', {
    state: (): State => {
        return {
            open: false,
            selected_photo_id: null
        }
    },

    actions: {
        toggleDrawer(open: boolean) {
            this.open = open
        },
        setPhotoIdSelected(photoId: number) {
            this.selected_photo_id = photoId
        }
    }
})