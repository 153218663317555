import app from './main'

import './bootstrap'

import './libs/bootstrap-sass'
import './libs/element-plus'

import "cropperjs/dist/cropper.css"

// Import custom scripts
import './scripts/index'

// Import custom css last to overwrite default styles
import '../sass/app.scss'

import './pages/home'
import './pages/events'
import './pages/photos'
import './pages/register'
import './pages/account'
import './pages/shop'
import './pages/committees'

import LazyLoad from "vanilla-lazyload"

new LazyLoad({
    elements_selector: ".lazyload"
})

app.mount(document.createElement('div'))