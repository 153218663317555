import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import Register from '../components/Register/Register.vue'

if(document.getElementById("registerForm")){
    const app1 = createApp({
        render() {
            return h(Register, convertDataSet('registerForm'))
        }
    })

    app1.mount('#registerForm')
}