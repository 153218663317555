<template>
    <el-dialog width="75%" v-model="loginDialogVisible" id="modalLogin">
        <img class="animation d-none d-lg-block" src="/images/login-bg.gif">
        <div class="form-block">
            <el-form :model="loginForm" :status-icon="true" :rules="rules" ref="loginFormRef" class="form-signin" :hide-required-asterisk=true @submit.prevent="submitForm(loginFormRef)">
                <div class="heading">
                    <img class="logo-img" src="https://voxvivarotterdam.imgix.net/logo.png?auto=compress,format&w=40&h=40&fit=clip" alt="Logo Vox Viva Rotterdam" width="40" height="40">
                    <h5 class="modal-title" id="modalLoginTitle">Hey, hallo &#128075;</h5>
                    <p class="modal-subtitle">Maar twee velden invullen!</p>
                </div>

                <div class="body">
                    <div id="loginError" class="text-danger" v-if="errors">
                        {{errors}}
                    </div>

                    <el-form-item label="E-mailadres" prop="email" hide-required-asterisk="true">
                        <el-input id="mail" v-model="loginForm.email" @keyup.enter="submitForm(loginFormRef)"></el-input>
                    </el-form-item>

                    <el-form-item label="Wachtwoord" prop="password" hide-required-asterisk="true">
                        <el-input v-model="loginForm.password" type="password" @keyup.enter="submitForm(loginFormRef)"></el-input>
                    </el-form-item>

                    <div class="actions">
                        <el-checkbox v-model="loginForm.remember">Onthoud mij</el-checkbox>
                        <p class="forgot-password"><a href="/wachtwoord-vergeten">Wachtwoord vergeten?</a></p>
                    </div>
                </div>

                <div class="bottom">
                    <el-form-item>
                        <el-button class="btn btn-blue" type="primary" @click="submitForm(loginFormRef)">Inloggen</el-button>
                    </el-form-item>
                    <p class="new-account">Nog geen account? <a href="/registreren" class="text-center">Registreer hier</a></p>
                </div>
            </el-form>
        </div>
    </el-dialog>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import http from "../libs/axios"

interface Login {
    email: string,
    password: string,
    remember: boolean
}

const loginFormRef = ref<FormInstance>()
const loginForm: Login = reactive({
    email: '',
    password: '',
    remember: false
})

const errors = ref('')
const loginDialogVisible = ref(false)

const rules = reactive<FormRules>({
    email: [
        { required: true, message: 'Vul een e-mailadres in', trigger: 'blur' },
        { type: 'email', message: 'Vul een correct e-mailadres in', trigger: ['blur'] }
    ],
    password: [
        { required: true, message: "Vul een wachtwoord in", trigger: "blur"},
    ]
})

// register click and touch events
document.querySelectorAll('.login-link').forEach(function (element) {
    element.addEventListener('click', showModal)
})

// doe listener (iets met directive) op link van login en open deze modal
function showModal() {
    loginDialogVisible.value = true;
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            http.post('/api/auth/login', loginForm)
                .then(response => {
                    errors.value = ''
                        
                    // this.$store.dispatch('getPaymentsDue');
                    // Reload page after succesful login
                    window.location.reload();
                })
                .catch((error) => {
                    errors.value = error.response.data
                })
        } else {
            return false;
        }
    });
}
</script>

<style lang="scss">
@import "@F/sass/components/_loginModal.scss";
</style>