<template>
    <el-drawer
        :title="'Tag'"
        :withHeader="false"
        :model-value="photoStore.open"
        :before-close="handleClose"
        @open="onOpenDrawer()"
        :direction="direction"
        class="photo-tag-drawer"
    >
        <div v-if="!loading">
            <div class="header">
                <div class="title">
                    <h4 class="blue-text mb-2">Taggen <i class="far fa-info-circle info-popup-trigger" @click="showInfoDialog = true"></i></h4>
                    <span class="close-drawer-button" @click="handleClose"><i class="far fa-times"></i></span>
                </div>
                <div class="d-none d-md-block">
                    <p class="confirmed-tags-title"><b>Bevestigde tags in deze foto ({{ confirmedTags.length }}):</b> <span v-if="confirmedTags.length == 0">Geen bevestigde tags voor deze foto.</span></p>
                    <div class="confirmed">
                        <div class="item" v-for="tag in confirmedTags">
                            <img class="profile-picture" :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/minified/' + tag.tagged_user.profile_pic" v-if="tag.tagged_user.profile_pic">
                            <img class="profile-picture" src="/images/profile-pic/profile-person.jpg" v-else>
                            <p class="name">{{ tag.tagged_user.name }}</p>
                        </div>
                    </div>
                </div>
                <el-collapse v-model="activeCollapse" accordion class="d-md-none collapse-tags-information">
                    <el-collapse-item name="1">
                        <p>
                            <b>Bevestigde tags in deze foto ({{ confirmedTags.length }}): </b>
                            <span v-if="confirmedTags.length > 0">
                                {{ confirmedTags.map(user => user.tagged_user.name).join(', ') }}
                            </span>
                            <span v-else>Geen bevestigde tags voor deze foto.</span>
                        </p>

                        <p class="mb-0">
                            <b>Tags van jou ({{ userTags.length }}): </b>
                            <span v-if="userTags.length > 0">
                                {{ userTags.map(user => user.tagged_user.name).join(', ') }}
                            </span>
                            <span v-else>Jij hebt geen tags aan deze foto toegevoegd.</span>
                        </p>
                    </el-collapse-item>
                </el-collapse>

                <p class="mb-0 d-none d-md-block">
                    <b>Tags van jou ({{ userTags.length }}): </b>
                    <span v-if="userTags.length > 0">
                        {{ userTags.map(user => user.tagged_user.name).join(', ') }}
                    </span>
                    <span v-else>Jij hebt geen tags aan deze foto toegevoegd.</span>
                </p>
            </div>

            <div class="members-list">
                <h5 class="blue-text">Leden</h5>
                <el-input type="text" v-model="nameFilter" placeholder="Zoek op naam" @input="textFilterChanged" @focus="hideContentMobile"></el-input>
                <div class="item" v-if="members.length == 0">
                    <p>Geen leden gevonden</p>
                </div>
                <div class="item" v-for="member in members" v-else>
                    <div class="profile-picture">
                        <img :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/minified/' + member.profile_pic" v-if="member.profile_pic">
                        <img src="/images/profile-pic/profile-person.jpg" v-else>
                    </div>
                    <p class="name">{{member.name}}</p>
                    <el-button class="btn btn-blue" @click="addTag(member.id)" v-if="!confirmedTagsIds.includes(member.id) && !userTagsIds.includes(member.id)"><i class="fas fa-plus"></i></el-button>
                    <el-button class="btn btn-delete" type="danger" @click="removeTag(member.id)" v-if="userTagsIds.includes(member.id) && !confirmedTagsIds.includes(member.id)"><i class="fas fa-times"></i></el-button>
                    <el-button class="btn btn-confirmed" type="success" v-if="confirmedTagsIds.includes(member.id)"><i class="fas fa-check"></i></el-button>
                </div>
            </div>
        </div>
        <div v-else>
            <el-skeleton :loading="true" :rows="10"></el-skeleton>
        </div>

        <el-dialog
            :model-value="showInfoDialog"
            title="Uitleg foto's taggen"
            :before-close="() => showInfoDialog = false"
        >
            <template #default>
                <h5 class="blue-text">Waarom moet ik dit doen?</h5>
                <p>Allereerst is dit de perfecte manier om de namen van mensen te leren kennen. Daarnaast kan iedereen foto's waar hij of zij op staat, makkelijk terugvinden bij zijn account. En uiteraard is er ook een achievement aan verbonden!</p>

                
                <h5 class="blue-text">Hoe werkt het?</h5>
                <p>Het is goed om een aantal dingen te weten:</p>
                <ul class="disc ml-0">
                    <li>Alle Vox Viva leden kunnen mensen op een foto taggen.</li>
                    <li>Tag alleen de mensen die als onderwerp op de foto staan. Dit is subjectief, maar zal meestal voor zich spreken. Bij groepsfoto ben je gewoon wat langer bezig 😉</li>
                    <li>Taggen is alleen mogelijk in de {{ props.config?.tagging_disable_threshold }} dagen nadat een fotoalbum op de website is geplaatst. Dit geldt pas op het moment dat taggen langer beschikbaar is zodat er meer tijd is om oudere albums ook langs te gaan.</li>
                    <li>Om te voorkomen dat mensen willekeurig gaan taggen voor de achievement, moeten {{ props.config?.confirmation_threshold }} mensen dezelfde persoon op een foto hebben getagd voordat de tag definitief is. Deze persoon is daarna ook niet meer te taggen.</li>
                    <li><strong class="blue-text">Als laatste:</strong> let op je databundel als je op je telefoon foto's bekijkt!</li>
                </ul>

                <button class="btn btn-blue mt-4" @click="showInfoDialog = false">Ik snap het. Aan de slag!</button>
            </template>
        </el-dialog>
    </el-drawer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { PropType, Ref } from 'vue'
import type { DrawerProps } from 'element-plus'
import { usePhotoStore } from '../../stores/photo'
import http from "../../libs/axios"
import _ from 'lodash'

interface Member {
    id: number,
    name: string,
    profile_pic: string,
    birthdate: number,
    phone: string
}

interface ConfigPhotos {
    confirmation_threshold: string,
    tagging_disable_threshold: string,
}

const props = defineProps({
    'config': Object as PropType<ConfigPhotos>
})
const photoStore = usePhotoStore()
const members: Ref<(Array<Member>)> = ref([])
const confirmedTags: Ref<(Array<Member>)> = ref([])
const userTags: Ref<(Array<Number>)> = ref([])
const nameFilter: Ref<String> = ref('')
const loading: Ref<Boolean> = ref(false)
const direction = ref<DrawerProps['direction']>('rtl')
const showInfoDialog: Ref<Boolean> = ref(false)
const activeCollapse = ref('1')

// If window size is less than 768px, change direction to 'bottom'. listen for change but also set initial value
if (window.innerWidth < 768) {
    direction.value = 'btt'
}
window.addEventListener('resize', () => {
    if (window.innerWidth < 768) {
        direction.value = 'btt'
    } else {
        direction.value = 'rtl'
    }
})


function onOpenDrawer() {
    loading.value = true
    loadMembers()
    getTags()
}

const confirmedTagsIds = computed(() => {
    let ids: Array<Number> = confirmedTags.value.map(item => {return item.tagged_user.id})
    
    return ids
})

const userTagsIds = computed(() => {
    let ids: Array<Number> = userTags.value.map(item => {return item.tagged_user.id})
    
    return ids
})

const textFilterChanged = _.debounce(() => {
    loadMembers()
}, 500);

function loadMembers() {
    http.post('/api/leden/all', {
        filters: {'name': nameFilter.value},
        perPage: 500
    })
    .then(response => {
        members.value = response.members
    })
    .catch(() => {return false})
}

function getTags() {
    http.get('/api/fotos/' + photoStore.selected_photo_id + '/tags')
    .then(response => {
        confirmedTags.value = response.confirmed_tags,
        userTags.value = response.user_tags,
        loading.value = false
    })
    .catch(() => {return false})
}

function addTag(member_id: Number) {
    http.post('/api/fotos/tag', {
        photo_id: photoStore.selected_photo_id,
        tagged_user_id: member_id
    })
    .then(() => {
        getTags()

        ElNotification({
            title: 'Getagd!',
            message: 'Tag succesvol toegevoegd. Dankjewel!',
            type: 'success',
            position: 'top-left',
            zIndex: 999999
        })
    })
    .catch(() => {return false})
}

function removeTag(member_id: Number) {
    http.delete('/api/fotos/tag/'+photoStore.selected_photo_id+'/'+member_id)
    .then(() => {
        getTags()

        ElNotification({
            title: 'Verwijderd!',
            message: 'Tag succesvol verwijderd.',
            type: 'success',
            position: 'top-left',
            zIndex: 999999
        })
    })
    .catch(() => {return false})
}

function hideContentMobile() {
    if (window.innerWidth < 768) {
        // Add d-none class to header
        activeCollapse.value = ''
    }
}

function handleClose () {
    loading.value = true
    photoStore.toggleDrawer(false)
}
</script>