<template>
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12">
            <h1 class="section-title">Registreren</h1>
            <p class="subtitle">Stap {{allSteps[currentStep].name}} van {{allSteps[totalSteps].name}}.</p>

            <div class="step-blocks">
                <div 
                    class="step" 
                    v-for="step in totalSteps" 
                    :key="step" 
                    :class="{ active: step == currentStep, 'd-none': !stepsDone.includes(step) }"
                    @click="goToStep(step, registerFormRef)"
                >
                    {{step}}
                </div>
            </div>

            <el-form
                ref="registerFormRef"
                :model="registerStore"
                :rules="allSteps[currentStep].rules"
                label-width="120px"
                label-position="top"
                :hide-required-asterisk="true"
                @submit.prevent="submitForm(registerFormRef)"
            >
                <component v-bind:is="allSteps[currentStep].component" />
            </el-form>

            <div class="errors" v-if="Object.keys(errors).length > 0">
                <p>De volgende {{ Object.keys(errors).length > 1 ? 'fouten zijn' : 'fout is' }} opgetreden:</p>
                <ul>
                    <li class="text-danger" v-for="error in errors" :key="error">{{ error[0] }}</li>
                </ul>
            </div>

            <div class="navigation">
                <div class="d-flex justify-content-end">
                    <el-button v-if="currentStep > 1" class="btn prev-btn" @click="goToStep(currentStep - 1, registerFormRef)">
                        Vorige stap
                    </el-button>
                    <el-button v-if="currentStep != totalSteps" class="btn" @click="goToStep(currentStep + 1, registerFormRef)">
                        Volgende stap
                    </el-button>
                    <el-button v-else class="btn" @click="submitForm(registerFormRef)" :disabled="loading">
                        {{(!loading ? 'Registreren' : 'Momentje...')}}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 d-none d-md-block">
            <div class="side-image">
                <img :src="'images/'+allSteps[currentStep].image" alt="Sfeerimpressie afbeelding">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { useRegisterStore } from '../../stores/register'
import moment from 'moment'
import http from "../../libs/axios"

import Step1 from "./Step1.vue"
import Step2 from "./Step2.vue"
import Step3 from "./Step3.vue"

const registerStore = useRegisterStore()

const validatePassConfirm = (rule: any, value: any, callback: any) => {
    if (value === '') {
        callback(new Error('Vul het wachtwoord opnieuw in'))
    } else if (value !== registerStore.password) {
        callback(new Error("De wachtwoorden komen niet overeen"))
    } else {
        callback()
    }
}

const loading = ref(false)
const currentStep = ref(1)
const totalSteps = ref(3)
const stepsDone = ref([1])
const errors = ref({})
const registerFormRef = ref<FormInstance>()
const allSteps = {
    1: {
        'name': 'een',
        'component': Step1,
        'image': 'registration/image1.jpg',
        'rules': reactive<FormRules>({
            first_name: [
                { required: true, message: "Vul jouw voornaam in", trigger: "blur"},
                { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
                { max: 255, message: "Voornaam te lang", trigger: "blur" },
            ],
            middle_name: [
                { min: 2, message: "Vul minstens 2 letters in", trigger: "blur" },
                { max: 20, message: "Vul maximaal 20 letters in", trigger: "blur" },
            ],
            last_name: [
                { required: true, message: "Vul jouw achternaam in", trigger: "blur"},
                { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
                { max: 255, message: "Achternaam te lang", trigger: "blur" },
            ],
            email: [
                { required: true, message: 'Vul jouw e-mailadres in', trigger: "blur" },
                { type: 'email', message: 'Vul een correct e-mailadres in', trigger: "blur" }
            ],
            phone: [
                { type: 'number', message: 'Vul alleen cijfers in', trigger: "blur" },
                { type: 'number', min: 10000000, max: 99999999, message: 'Vul de 8 cijfers na 06- in', trigger: "blur" },
            ],
            birthdate: [
                { required: true, message: 'Vul een geboortedatum in', trigger: "blur" },
                { type: 'date', message: 'Vul een correcte datum in', trigger: "blur" }
            ],
            residence: [
                { required: true, message: "Vul een woonplaats in", trigger: "change"},
                { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
                { max: 255, message: "Woonplaats te lang", trigger: "blur" },
            ],
        })
    },
    2: {
        'name': 'twee',
        'component': Step2,
        'image': 'registration/image2.jpg',
        'rules': reactive<FormRules>({
            study: [
                { required: true, message: "Vul een studie in", trigger: "change" },
                { min: 2, message: "Vul minstens 2 letters in", trigger: "change" },
                { max: 255, message: "Studie te lang", trigger: "blur" }
            ],
            institution: [
                { required: true, message: "Vul een studie instelling in", trigger: "change" },
                { min: 2, message: "Vul minstens 2 letters in", trigger: "change" },
                { max: 255, message: "Instelling te lang", trigger: "blur" },
            ],
            study_site: [
                { required: true, message: "Vul een studie plaats in", trigger: "change" },
                { min: 2, message: "Vul minstens 2 letters in", trigger: "change" },
                { max: 255, message: "Studie plaats te lang", trigger: "blur" },
            ],
            study_start: [
                { required: true, message: "Vul een startjaar in", trigger: "blur" },
                { type: 'number', message: 'Vul alleen cijfers in', trigger: "blur" },
                { type: 'number', min: (moment().year() - 40), max: (moment().year() + 2), message: "Vul een jaar tussen " + (moment().year() - 40) + " en " + (moment().year() + 2) + " in", trigger: "blur" }
            ]
        })
    },
    3: {
        'name': 'drie',
        'component': Step3,
        'image': 'registration/image3.jpg',
        'rules': reactive<FormRules>({
            profile_pic: [
                { required: true, message: "Upload een profielfoto", trigger: "blur" },
            ],
            password: [
                { required: true, message: "Vul een wachtwoord in", trigger: "blur" },
                { min: 5, message: "Vul minstens 5 karakters in", trigger: "blur" },
                { max: 25, message: "Vul maximaal 25 karakters in", trigger: "blur" },
            ],
            password_confirmation: [
                { validator: validatePassConfirm, trigger: "blur" },
            ],
            terms_of_use: [
                { type: 'boolean', required: true, message: "Accepteer de algemene voorwaarden", trigger: "change" }
            ]
        })
    },
}

function goToStep(step: number, formEl: FormInstance | undefined) {
    if (step <= currentStep.value) {
        currentStep.value = step
        return
    }

    if (!formEl) return
    
    formEl.validate((valid) => {
        if (valid) {
            if (!stepsDone.value.includes(step)) {
                stepsDone.value.push(step)
            }

            currentStep.value = step
            return
        } else {
            return false
        }
    });
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (!valid)
            return false

        loading.value = true

        http.post('/api/register/saveUser', registerStore.$state)
            .then(() => {
                errors.value = {}
                window.location.assign('/registreren/voltooid')
            })
            .catch((error) => {
                loading.value = false
                errors.value = error.response.data.errors
            })
            .finally(() => {
                loading.value = false
            })
    });
}
</script>