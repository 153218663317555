import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './components/App.vue'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import Login from './components/Login.vue'
import Logout from './components/Logout.vue'
import NotificationBell from './components/NotificationBell.vue'
import { convertDataSet } from './pages/helper'

// Load components
// Login modal
if (document.getElementById("login-modal")) {
    const app1 = createApp({
        render() {
          return h(Login)
        }
    })
    
    app1.mount('#login-modal')
}

if (document.getElementById("logout-link")) {
    const app2 = createApp({
        render() {
          return h(Logout)
        }
    })
    
    app2.mount('#logout-link')
}

if (document.getElementById("logout-link-mobile")) {
  const app3 = createApp({
      render() {
        return h(Logout)
      }
  })
  
  app3.mount('#logout-link-mobile')
}

if (document.getElementById("notificationBell")) {
  const app4 = createApp({
      render() {
        return h(NotificationBell, convertDataSet('notificationBell'))
      }
  })
  
  app4.mount('#notificationBell')
}

const app = createApp(App)
app.use(pinia)

export default app