import axios from 'axios';
import { ElNotification } from 'element-plus';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Response
axios.interceptors.response.use(
    (response) => {
        if (response.data.data) {
            return response.data.data;
        } else {
            return response;
        }
    },
    (error) => {
        if (!error.response.data.message) {
            ElNotification.error({
                title: 'Error',
                message: 'Er ging iets fout!'
            });
        } else {
            enum NotificationPosition {
                TOP_LEFT = 'top-left',
                TOP_RIGHT = 'top-right',
                BOTTOM_LEFT = 'bottom-left',
                BOTTOM_RIGHT = 'bottom-right'
            }

            let position: NotificationPosition = NotificationPosition.TOP_RIGHT;

            if (error.response.data.position) {
                position = error.response.data.position;
            }

            ElNotification.error({
                title: 'Error',
                message: error.response.data.message,
                position: position,
                zIndex: 99999
            });
        }

        return Promise.reject(error);
    }
);

export default axios;