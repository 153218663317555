import { createApp, h } from 'vue'
import { convertDataSet } from './helper'

import EventList from '../components/EventList/EventList.vue'
import Register from '../components/Event/Register.vue'
import Map from '../components/Event/Map.vue'

if(document.getElementById("eventsList")){
    const app1 = createApp({
        render() {
            return h(EventList, convertDataSet('eventsList'))
        }
    })

    app1.mount('#eventsList')
}

if(document.getElementById("eventsListPast")){
    const app2 = createApp({
        render() {
            return h(EventList, convertDataSet('eventsListPast'))
        }
    })

    app2.mount('#eventsListPast')
}

if(document.getElementById("eventRegister")){
    const app4 = createApp({
        render() {
            return h(Register, convertDataSet('eventRegister'))
        }
    })

    app4.mount('#eventRegister')
}

if(document.getElementById("eventMap")){
    const app5 = createApp({
        render() {
            return h(Map, convertDataSet('eventMap'))
        }
    })

    app5.mount('#eventMap')
}

if (document.getElementById('showAllRegistrations')) {
    document.getElementById('showAllRegistrations').addEventListener('click', function(e) {
        e.preventDefault();
        var locationWrapper = document.querySelector('.location-wrapper');
        var registrationsWrapper = document.querySelector('.all-registrations-wrapper');

        locationWrapper.classList.add('collapsed');
        registrationsWrapper.classList.remove('collapsed');
    });

    document.getElementById('hideAllRegistrations').addEventListener('click', function(e) {
        e.preventDefault();
        var locationWrapper = document.querySelector('.location-wrapper');
        var registrationsWrapper = document.querySelector('.all-registrations-wrapper');

        locationWrapper.classList.remove('collapsed');
        registrationsWrapper.classList.add('collapsed');
    });
}