<template>
    <a href="javascript:void(0)" rel="nofollow" @click="logout">Uitloggen</a>
</template>

<script setup lang="ts">
import http from "../libs/axios"

function logout() {
    http.get('/api/auth/logout')
    .then(() => {                
        // Reload page after succesful logout
        window.location.reload();
    })
}
</script>