<template>
    <div>
        <button href="javascript:void(0)" id="notificationsDropdown" class="notifications-toggle" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false" @click="onPopoverShow">
            <i class="fa-light fa-bell icon-badge-icon"></i>
            <span class="icon-badge" v-if="notificationCountVal > 0">{{ notificationCountVal }}</span>
        </button>
        <div class="dropdown-menu notifications-menu" aria-labelledby="notificationsDropdown">
            <div class="notifications-list">
                <div v-for="(notification, index) in notifications" :key="notification.id" v-if="firstShow && Object.keys(notifications).length > 0" class="item" :class="{read: notification.read_at}">
                    <div class="title">
                        <h5>{{ notification.data.title }}</h5>
                        <span class="unread" :class="{active: !notification.read_at}"></span>
                    </div>
                    <div class="content">
                        <div class="content-inner">
                            <p>{{ notification.data.body }}</p>
                            <a v-if="notification.data.action" class="btn btn-blue" role="button" :href="notification.data.action.url" @click.stop.prevent="markReadNotification(notification.id, index, true)">
                                {{ notification.data.action.text }}
                            </a>
                        </div>

                        <div class="extra">
                            <el-dropdown trigger="click">
                                <i class="fa-regular fa-ellipsis-vertical"></i>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-if="!notification.read_at" @click="markReadNotification(notification.id, index, false)">Markeer als gelezen</el-dropdown-item>
                                        <el-dropdown-item v-else @click="markUnreadNotification(notification.id, index)">Markeer als ongelezen</el-dropdown-item>
                                        <el-dropdown-item @click="deleteNotification(notification.id, index)">Verwijder notificatie</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else-if="!firstShow">
                    <p>Notificaties aan het laden...</p>
                </div>
                <div class="empty" v-else>
                    <p>Je bent helemaal bij!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { Ref } from 'vue'
import http from 'axios'

import { Notification } from '../types/notification'

const props = defineProps({
    'notificationcount': Number
})

const notifications: Ref<(Array<Notification>)> = ref([])
const firstShow: Ref<boolean> = ref(false)
let notificationCountVal: Ref<number> = ref(0)

onMounted(() => {
    if (props.notificationcount)
        notificationCountVal.value = props.notificationcount
})

const onPopoverShow = async () => {
    if (!firstShow.value) {
        await fetchNotifications()
        firstShow.value = true;
    }
}

async function fetchNotifications() {
    await http.get('/api/notificaties')
        .then((response: any) => {
            notifications.value = response
        })
        .catch(() => {
            return false
        });
}

function markReadNotification(id: string, index: number, redirect: boolean) {
    http.put(`/api/notificaties/lees/${id}`)
        .then(function (response: any) {
            if(response){
                notifications.value[index].read_at = new Date().toDateString()
                notificationCountVal.value--

                if (redirect) {
                    // Redirect on successful response
                    window.location.href = notifications.value[index].data.action.url;
                }
            }
        })
        .catch(() => {
            return false
        })
}

function markUnreadNotification(id: string, index: number) {
    http.put(`/api/notificaties/open/${id}`)
        .then(function (response: any) {
            if(response){
                notifications.value[index].read_at = null
                notificationCountVal.value++
            }
        })
        .catch(() => {
            return false
        })
}

function deleteNotification(id: string, index: number) {
    http.delete(`/api/notificaties/${id}`)
        .then(function (response: any) {
            if(response){
                // Only down if notification wasn't read yet
                if (!notifications.value[index].read_at) {
                    notificationCountVal.value--
                }
                notifications.value.splice(index, 1);
            }
        })
        .catch(() => {
            return false
        })
}
</script>

<style lang="scss">
@import "@F/sass/components/_notificationBell.scss";
</style>