function convertDataSet(elementId) {
    let props = {}
    
    if (document.getElementById(elementId)) {
        let dataset = Object.assign({}, document.getElementById(elementId).dataset);

        Object.keys(dataset).forEach(key => {
            if (IsJsonString(dataset[key])) {
                props[key] = JSON.parse(dataset[key]);
            } else {
                props[key] = dataset[key];
            }
        });
    }

    return props
}

function convertDataSetElement(element) {
    let props = {}

    if (element) {
        let dataset = Object.assign({}, element.dataset);
    
        Object.keys(dataset).forEach(key => {
            if (IsJsonString(dataset[key])) {
                props[key] = JSON.parse(dataset[key]);
            } else {
                if (isNaN(Number(dataset[key]))) {
                    props[key] = dataset[key];
                } else {
                    props[key] = Number(dataset[key]);
                }
            }
        });
    }

    return props
}

function IsJsonString(str) {
    try {
      var json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
}

export { convertDataSet, convertDataSetElement };