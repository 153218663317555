import { createApp, h } from 'vue'
import {convertDataSet} from './helper'

import EventsCalendar from '../components/Home/EventsCalendar.vue'
import InstaPosts from '../components/Home/InstaPosts.vue'

// Events calendar homepage
if(document.getElementById("instaPosts")){
    const app1 = createApp({
        render() {
            return h(InstaPosts, convertDataSet('instaPosts'))
        }
    })

    app1.mount('#instaPosts')
}

// Events calendar homepage
if(document.getElementById("events-calendar")){

    const app2 = createApp({
        render() {
          return h(EventsCalendar, convertDataSet('events-calendar') )
        }
    })
    
    app2.mount('#events-calendar')
}