<template>
    <div class="container">
        <div class="row headings">
            <div class="col-md-3">
                <h3 class="heading d-none d-md-block">Details</h3>
            </div>
            <div class="col-md-9">
                <div class="row justify-content-between">
                    <div class="col">
                        <h3 class="heading">Leden <span class="count">{{results_count}}</span></h3>
                    </div>
                    <div class="col sorting">
                        <label for="sort-by">Sorteer op</label>
                        <el-select v-model="sortBy" placeholder="Sorteer op" size="small" @change="filterChanged">
                            <el-option key="name_asc" label="Naam (A-Z)" value="name_asc"></el-option>
                            <el-option key="name_desc" label="Naam (Z-A)" value="name_desc"></el-option>
                            <el-option key="created_at_asc" label="Lid sinds (Lang-Kort)" value="created_at_asc"></el-option>
                            <el-option key="created_at_desc" label="Lid sinds (Kort-Lang)" value="created_at_desc"></el-option>
                            <el-option key="birthdate_asc" label="Geboortedatum (Oud-Jong)" value="birthdate_asc"></el-option>
                            <el-option key="birthdate_desc" label="Geboortedatum (Jong-Oud)" value="birthdate_desc"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 filters">
                <filters 
                    v-model:filters="filters"
                    :filter-options="filterOptions"
                    @filter-update="filterChanged"
                ></filters>
            </div>

            <div class="col-md-9 results">
                <div class="result-list row">
                    <member 
                        v-for="member in results"
                        :member="member"
                        :key="'memberlist-'+member.id"
                        @member-focussed="handleMemberFocus"></member>

                    <div class="col-12" v-if="loading">
                        <el-skeleton animated class="row" v-if="loading" style="width: auto; row-gap: 1.8rem;">
                            <template #template>
                                <div v-for="index in 12" :key="index" class="col-xl-3 col-lg-4 col-sm-6 col-12" >
                                    <el-skeleton-item variant="image" style="height: 200px; border-radius: 20px 20px 0 0;" />
                                    <div style="padding: 0.7rem; height: 150px; border: 1px solid #dee2e6; border-radius: 0 0 20px 20px;">
                                        <el-skeleton-item variant="text" style="height: 27px;" />
                                        <el-skeleton-item variant="text" style="width: 60%" />
                                        <el-skeleton-item variant="text" style="width: 60%" />
                                    </div>
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                </div>

                <InfiniteLoading 
                    v-if="!infiniteScrollDisabled"
                    @infinite="loadMoreResults"
                    target="result-list"
                    :firstload="true"
                    :distance="-150"/>
            </div>
        </div>
        <div id="scrollToTop" @click="scrollToTop()"><i class="fa-solid fa-arrow-up"></i></div>
        <member-drawer v-model:showMemberDrawer="openMemberDrawer" :memberId="focussedMemberId"></member-drawer>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import type { Ref } from 'vue'
// @ts-ignore
import InfiniteLoading from "v3-infinite-loading"

import Member from './Member.vue'
import MemberDrawer from './MemberDrawer.vue'
import Filters from './Filters.vue'
import http from "../../libs/axios"

interface Member {
    id: number,
    name: string,
    profile_pic: string,
    birthdate: number,
    phone: string
}

const loading = ref(true)
const infiniteScrollDisabled = ref(false)
const openMemberDrawer = ref(false)
const focussedMemberId = ref(0)
const results: Ref<(Array<Member>)> = ref([])
const results_count = ref(0)
const page = ref(0)
const sortBy = ref('name_asc')
let filters = reactive({
    name: '',
    phone: null,
    residence: [],
    study: [],
    study_site: [],
    study_institution: []
})
const filterOptions = ref({
    residences: [{
        'label': '',
        'count': 0
    }],
    studies: [{
        'label': '',
        'count': 0
    }],
    study_sites: [{
        'label': '',
        'count': 0
    }],
    study_institutions: [{
        'label': '',
        'count': 0
    }]
})

function filterChanged () {
    page.value = 0
    results.value = []
    results_count.value = 0
    infiniteScrollDisabled.value = false
    scrollToTop()
    loadMoreResults()
}

function loadMembers() {
    loading.value = true
    http.post('/api/leden', {
        page: page.value,
        filters: filters.value,
        sorting: sortBy.value,
        perPage: 12
    })
        .then(response => {
            loading.value = false
            results.value = results.value.concat(response.members);
            results_count.value = response.total;
            filterOptions.value = response.filters;

            if (results.value.length >= response.total) {
                infiniteScrollDisabled.value = true;
            }
        })
        .catch(error => {
            console.log(error);
        });
}

const loadMoreResults = async () => {
    page.value++;
    await loadMembers();
}

loadMoreResults()

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

function handleMemberFocus(member_id: number) {
    focussedMemberId.value = member_id;
    openMemberDrawer.value = true;
}
    
</script>