import { defineStore } from 'pinia'
import http from "../libs/axios"

interface CartItem {
    product_id: number,
    name: string,
    thumbnail: string,
    price: number,
    options: Array<{}>
}

interface State {
    open: boolean,
    items: Array<CartItem>,
    item_count: number,
    total_price: number,
    discount_code: {},
    discount_price: number,
}

export const useCartStore = defineStore('cart', {
    state: (): State => {
        return {
            open: false,
            items: [],
            item_count: 0,
            total_price: 0,
            discount_code: {},
            discount_price: 0
        }
    },
    actions: {
        addItemToCart(item: CartItem) {
            this.item_count++
            this.total_price += item.price
            this.items.push(item)
        },
        removeItemFromCart(itemIndex: number) {
            let cartItem = this.items[itemIndex]
    
            this.items.splice(itemIndex, 1)
            this.item_count--
            this.total_price -= cartItem.price
        },
        toggleCart(open: boolean) {
            this.open = open
        },
        emptyCart() {
            this.item_count = 0
            this.total_price = 0
            this.discount_code = {}
            this.discount_price = 0
            this.items = []
        },
        async applyDiscount(code: string) {
            await http.post('/api/producten/kortingscode', {code: code})
                .then((response) => {
                    this.discount_code = response
                    this.discount_price = response.discount_amount
                }).catch(() => {
                    this.discount_code = {}
                    this.discount_price = 0
                    return false
                })
        },
        deleteDiscount() {
            this.discount_code = {}
            this.discount_price = 0
        }
    },
    persist: true,
})