<template>
    <el-drawer
        :model-value="showMemberDrawer"
        :withHeader="false"
        :before-close="handleClose"
        @open="onOpenDrawer()"
        direction="rtl"
        class="member-drawer"
    >
        <template v-if="!loading">
            <div id="residence"></div>
            <div class="header">
                <a data-fancybox :href="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + member.profile_pic" data-type="image" v-if="member.profile_pic">
                    <img :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + member.profile_pic" class="profile-picture" :alt="member.name">
                </a>
                <img src="/images/profile-pic/profile-person.jpg" class="profile-picture" alt="Profiel foto onbekend" v-else>
                <h3>{{member.name}}</h3>
                <p class="contact">{{member.email}} <template v-if="member.phone"> - {{member.phone}}</template></p>
                <span class="close-drawer-button" @click="handleClose"><i class="fa-regular fa-xmark"></i></span>

                <div class="info">
                    <div class="row">
                        <div class="col-12 col-sm">
                            <h6>Actief sinds</h6>
                            <p>{{formatDate(member.created_at)}}</p>
                        </div>
                        <div class="col-12 col-sm">
                            <h6>Woonplaats</h6>
                            <p>{{member.residence}}</p>
                        </div>
                        <div class="col-12 col-sm">
                            <h6>Geboortedatum</h6>
                            <p>{{ formatUnixDate(member.birthdate, 'D MMMM YYYY') }}</p>
                        </div>
                    </div>
                    <h5 class="subtitle">Neem contact op:</h5>
                    <div class="row contact-buttons">
                        <div class="col-auto" v-if="member.phone">
                            <a :href="'https://wa.me/'+removePhoneNumberPrefix(member.phone?.toString(), true)" target="_blank" class="cta-button whatsapp"><i class="fa-brands fa-whatsapp"></i></a>
                        </div>

                        <div class="col-auto" v-if="member.phone">
                            <a :href="'tel:'+member.phone" class="cta-button phone"><i class="fa-light fa-phone"></i></a>
                        </div>

                        <div class="col-auto">
                            <a :href="'mailto:'+member.email" class="cta-button mail"><i class="fa-light fa-envelope"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details">
                <el-collapse v-model="activeSections">
                    <el-collapse-item  name="1" v-if="member.studies" class="section">
                        <template #title>
                            <h4>Studies <span class="count">({{member.studies.length}})</span></h4>
                        </template>
                        <div class="item" v-for="study in member.studies" :key="'study-'+study.id">
                            <h6>{{study.study}}</h6>
                            <p>Aan {{study.institution}} in {{study.study_site}} sinds {{ formatUnixDate(study.study_start, 'D MMMM YYYY')}}</p>
                        </div>
                    </el-collapse-item>

                    <el-collapse-item name="2" v-if="member.committees" class="section">
                        <template #title>
                            <h4>Commissies <span class="count">({{member.committees.length}})</span></h4>
                        </template>
                        <div class="item" v-for="committee in member.committees" :key="'committee-'+committee.id">
                            <h6>{{committee.committee.name}} <template v-if="committee.active_until == null"><i class="fa-solid fa-circle active-icon"></i> <span class="active-badge">Actief</span></template></h6>
                            <p>Actief vanaf {{ formatUnixDate(committee.active_since, 'D MMMM YYYY') }} tot {{ (committee.active_until != null ? formatUnixDate(committee.active_until, 'D MMMM YYYY') : 'heden') }}</p>
                        </div>
                    </el-collapse-item>

                    <el-collapse-item name="3" v-if="member.achievements" class="section">
                        <template #title>
                            <h4>Achievements <span class="count">({{Object.keys(member.achievements).length}})</span></h4>
                        </template>
                        <div class="achievements">
                            <div class="item achievement" v-for="achievement in member.achievements">
                                <div class="icon" :style="'background-color: ' + achievement.color" v-if="achievement.type == 1">
                                    <i class="fa-solid" :class="'fa-'+achievement.icon"></i>
                                </div>
                                <div class="icon" :style="'background-color: ' + (achievement.levels_done.includes('gold') 
                                                                                ? '#FFD700' : (achievement.levels_done.includes('silver') 
                                                                                ? '#C0C0C0' : '#cd7f32'))" v-if="achievement.type == 2">
                                    <i class="fa-solid" :class="'fa-'+achievement.icon"></i>
                                </div>
                                <div class="text">
                                    <h6>{{ achievement.name }} <span v-if="achievement.type == 2">({{ (achievement.levels_done.includes('gold') 
                                                                    ? '3' : (achievement.levels_done.includes('silver') 
                                                                    ? '2' : '1')) }}/3)</span></h6>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </template>
    </el-drawer>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { Ref } from 'vue'
import http from "../../libs/axios"
import { formatDate, formatUnixDate, removePhoneNumberPrefix } from "@F/js/helpers.ts"
import { Loader } from "@googlemaps/js-api-loader"

import { Achievement } from '../../types/achievement'

const props = defineProps({
    'memberId': Number,
    'showMemberDrawer': Boolean
})
const emit = defineEmits(['update:showMemberDrawer'])

interface Member {
    id: number,
    name: string,
    email: string,
    gender: string,
    profile_pic: string | null,
    birthdate: number,
    phone: string | null,
    residence: string,
    created_at: string,
    committees: Array<MemberCommittee>,
    studies: Array<MemberStudy>,
    achievements: Array<Achievement>
}

interface MemberCommittee {
    id: number,
    active: boolean,
    active_since: number,
    active_until: number,
    committee: {
        id: number,
        name: string
    }
}

interface MemberStudy {
    id: number,
    user_id: number,
    study: string,
    study_start: number,
    institution: string,
    study_site: string
}

const loading = ref(true)
const member: Ref<(Member)> = ref({
    id: 0,
    name: '',
    email: '',
    gender: 'm',
    profile_pic: null,
    birthdate: 0,
    phone: null,
    residence: '',
    created_at: '',
    committees: [],
    studies: [],
    achievements: []
})
const map = ref()
const activeSections = ref(['1', '2', '3'])

async function onOpenDrawer() {
    loading.value = true;
    await loadMemberDetails();

    // if (!this.map) {
        initMap()
    // }

    // Fancybox.bind("[data-fancybox");
}

async function loadMemberDetails() {
    await http.get('/api/leden/' + props.memberId)
        .then(response => {
            member.value = response
            loading.value = false
        })
        .catch(error => {
            console.log(error)
        });
}

function handleClose () {
    emit('update:showMemberDrawer', false)
}

function initMap() {
    const loader = new Loader({
        apiKey: "AIzaSyBAAhWImY-ZImjZsrMxVgCkR8KZR6J5hww",
        version: "weekly",
        libraries: ["places"]
    });

    loader.load().then((google) => {
        const mapElement = document.getElementById('residence');
        const map = new google.maps.Map(mapElement, {
            zoom: 10,
            disableDefaultUI: true,
            gestureHandling: 'none'
        });
        const placesService = new google.maps.places.PlacesService(map);

        let request = {
            query: String(member.value.residence),
            fields: ["geometry"],
            locationBias: {
                north: 53.5104033474,
                south: 50.803721015, 
                west: 3.31497114423, 
                east: 7.09205325687,
            }
        };

        placesService.findPlaceFromQuery(request, (results: any, status: void) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                map.setCenter(results[0].geometry.location);
            }
        });
        
        // const image = '/images/marker.png';
        // var marker = new google.maps.Marker({
        //     position: this.markerLocation,
        //     map: map,
        //     icon: image
        // });

        // marker.setMap(map);

        map.value = map;
    });  
}
</script>
