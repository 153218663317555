<template>
    <tr v-if="event">
        <td class="regs d-none">
            <ul></ul>
        </td>
        <td class="date">
            <a :href="'/agenda/' + event.id">
                <p class="day">{{showDate(event.start_event, 'D')}}</p><p class="month">{{showDate(event.start_event, 'MMMM')}}</p>
            </a>
        </td>
        <td class="info">
            <h3 style="word-break: break-all;">{{event.name}}</h3>
            <h5>{{event.extra_name}}</h5>
        </td>
        <td class="links">
            <p>
                <a v-if="!event.location_known" href="javascript:void(0)" style="pointer-events: none;"><i class="fa-solid fa-location-dot"></i>Locatie onbekend</a>
                <a v-else :href="'https://www.google.com/maps/search/?api=1&query=' + event.address?.replace(' ', '+') + '+' + event.city?.replace(' ', '+')" target="_blank" title="Open in google maps" rel="nofollow"><i class="fa-solid fa-location-dot"></i>{{ event.address }}, {{ event.city}}</a>
            </p>
            <p>
                <a :href="google_calendar_link" target="_blank" title="Toevoegen aan Google agenda" rel="nofollow">
                    <i class="fa-solid fa-clock"></i>
                    {{showDate(event.start_event, 'k:mm')}} 
                    <template v-if="event.end_event">({{showDate(event.start_event, 'Do')}}) tot {{showDate(event.end_event, 'k:mm (Do)')}}</template>
                </a>
            </p>
        </td>
        <td class="more">
            <a class="btn btn-blue" :href="'/agenda/' + event.id">Bekijken</a>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { PropType } from 'vue'
//@ts-ignore
import moment from 'moment/min/moment-with-locales'

interface Event {
    id: number,
    address: string | null,
    city: string | null,
    costs: number,
    end_event: number | null,
    start_event: number,
    name: string,
    extra_name: string,
    lat: number | null,
    lng: number | null,
    location: string | null,
    location_known: boolean
}

const props = defineProps({
    'event': Object as PropType<Event>
})

const google_calendar_link = ref('')

onMounted(() => {
    addCalendarLink()
})

function showDate (start: number, format: string, add: number = 0) {
    moment.locale('nl');
    let date = moment.unix(start)

    if (add) {
        date.add(add, 'hours')
    }

    return date.format(format)
}

function addCalendarLink() {
    if (!props.event)
        return false
    
    let value = props.event;
    let date = '';

    // Default link
    let link = 'https://calendar.google.com/calendar/r/eventedit?text=' + value.name + '+Vox+Viva'

    // Add date
    if (value.end_event) {
        date = showDate(value.start_event, 'YYYYMMDD') + 'T' + showDate(value.start_event, 'kkmmss') + '/' + showDate(value.end_event, 'YYYYMMDD') + 'T' + showDate(value.end_event, 'kkmmss')
    } else {
        date = showDate(value.start_event, 'YYYYMMDD') + 'T' + showDate(value.start_event, 'kkmmss') + '/' + showDate(value.start_event, 'YYYYMMDD', 2) + 'T' + showDate(value.start_event, 'kkmmss', 2)
    }

    link += '&dates=' + date

    // Add location
    if (value.location_known && value.address && value.city) {
        link+= '&location=' + value.address.replace(' ', '+') + '+' + value.city.replace(' ', '+')
    }
    
    // Add extra_name to description
    link += '&details=<b>' + value.extra_name + '</b>'

    google_calendar_link.value = link
}
</script>
