<template>
    <div class="form-step">
        <div class="row">
            <div class="col-md-6 avatar-block">
                <profile-pic v-model:profilePicDialogVisible="profilePicDialogVisible"></profile-pic>
                <div class="row">
                    <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div class="avatar" @click="profilePicDialogVisible=true">
                            <img v-bind:src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + profile_pic" v-if="profile_pic">
                        </div>
                    </div>
                    <div class="col-xxl-8 col-xl-7 col-lg-6 col-md-12 col-sm-6 col-12 avatar-info">
                        <p>Upload profiel foto</p>
                        <p class="text-small">Alleen .jpg/.jpeg/.png bestanden</p>
                        <p class="text-small">Bestandsgrootte kleiner dan 4MB</p>
                        <el-button @click="profilePicDialogVisible=true">{{(!profile_pic ? 'Uploaden' : 'Veranderen')}}</el-button>
                    </div>
                </div>

                <el-form-item prop="profilePic" class="empty">
                    <el-input type="hidden" v-model="profile_pic"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-6">
                <el-form-item label="Wachtwoord" prop="password">
                    <el-input 
                        v-model="password" 
                        placeholder="Tussen de 5-25 tekens"
                        type="password"
                    ></el-input>
                </el-form-item> 

                <el-form-item label="Wachtwoord bevestigen" prop="password_confirmation">
                    <el-input 
                        v-model="password_confirmation"
                        type="password"
                    ></el-input>
                </el-form-item>

                <el-form-item label="Algemene voorwaarden" prop="termsOfUse" class="checkbox-item general-terms-checkbox">
                    <p class="explanation">Bekijk de <a href="javascript:void(0)" @click="termsOfUseDrawerVisible=true">algemene voorwaarden</a></p>
                    <el-checkbox name="termsOfUse" v-model="terms_of_use">Ik ga akkoord met de algemene voorwaarden</el-checkbox>

                    <el-drawer
                        id="termsOfUseDrawer"
                        title="Onze algemene voorwaarden"
                        v-model="termsOfUseDrawerVisible"
                        direction="rtl"
                        size="75%"
                        @open="getGeneralTerms">
                        <div v-if="generalTerms.content">
                            <p>Laatst bijgewerkt: {{ formatDate(generalTerms.updated_at) }}</p>
                            <div v-html="generalTerms.content.text"></div>
                        </div>
                    </el-drawer>
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRegisterStore } from '../../stores/register'
import { storeToRefs } from 'pinia'
import http from '../../libs/axios'
import moment from 'moment'
import ProfilePic from './ProfilePic.vue'

const registerStore = useRegisterStore()
const { profile_pic, password, password_confirmation, terms_of_use } = storeToRefs(registerStore)

const profilePicDialogVisible = ref(false)
const termsOfUseDrawerVisible = ref(false)
const generalTerms = ref({})

const getGeneralTerms = () => {
    http.get('/api/register/algemene-voorwaarden')
    .then(function (response: any) {
        generalTerms.value = response
    })
    .catch(function () {
        return false
    })
}

function formatDate(timestamp: string, format: string = 'DD-MM-YYYY') {
    return moment(timestamp).format(format);
}
</script>