<template>
    <div>
        <div class="avatar" @click="profilePicDialogVisible=true">
            <img v-bind:src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + profilePicture" v-if="profilePicture">
        </div>

        <el-dialog 
            id="modalProfilePic"
            title="Profielfoto bijwerken"
            v-model="profilePicDialogVisible"
            :before-close="closeModal"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px"
        >
            <div class="modal-body">
                <p class="text-danger" v-if="errors">{{errors.message}}</p>
                <div class="el-upload__tip" slot="tip" v-if="uploaded_image == ''">.jpg/.jpeg/.png bestanden met een grootte kleiner dan 4MB</div>

                <div class="img-container" v-if="uploaded_image != ''">
                    <img id="image" v-bind:src="uploaded_image" v-on:load="initCropper">
                </div>

                <div class="zoom-profile-pic" v-if="uploaded_image != ''">
                    <el-slider
                        v-model="cropperZoom"
                        :show-tooltip="false"
                        @input="zoom"
                    ></el-slider>
                </div>
            </div>

            <template #footer>
                <div class="buttons">
                    <el-upload
                        action=""
                        accept=".jpg, .jpeg, .png"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="changeImage">
                        <template #trigger>
                            <el-button type="primary" v-bind:class="{ 'd-none': uploaded_image != '' }"><i class="fa-regular fa-plus"></i>Foto uploaden</el-button>
                        </template>
                        <el-button type="primary" @click="uploadImage" v-if="uploaded_image != ''"><i class="fa-regular fa-floppy-disk"></i>Foto opslaan</el-button>
                    </el-upload>

                    <div>
                        <el-button @click="closeModal">Annuleren</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from "vue"
import type { Ref } from "vue"
import { useRegisterStore } from '../../stores/register'
import Cropper from 'cropperjs'
import http from "../../libs/axios"
import { ElMessageBox } from 'element-plus'

const props = defineProps(['profilePicture', 'page'])
const registerStore = useRegisterStore()

let cropper : any
const profilePicDialogVisible: Ref<boolean> = ref(false)
const uploaded_image = ref('')
const cropperZoom = ref(0)
const imageData = reactive({
    image: false,
    'x': 0,
    'y': 0,
    'width': 0,
    'height': 0
})
const errors = ref({})


function closeModal(confirm?: boolean = true) {
    if (cropper && confirm) {
        ElMessageBox.confirm('Weet je zeker dat je je wijzigingen wilt verwijderen?')
        .then(() => {
            uploaded_image.value = '';
            destroyCropper()
            
            profilePicDialogVisible.value = false
        })
        .catch(() => {});
    } else {
        uploaded_image.value = ''
        destroyCropper()

        profilePicDialogVisible.value = false
    }
}

function changeImage(response : any) {
    imageData.image = response.raw;
    uploaded_image.value = URL.createObjectURL(response.raw);
}

function initCropper() {
    destroyCropper();

    const image = document.getElementById('image');

    if (!image)
        return false

    cropper = new Cropper(image, {
        aspectRatio: 1,
        autoCropArea: 1,
        background: false,
        center: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        dragMode: 'move',
        highlight: false,
        modal: true,
        guides: false,
        rotatable: false,
        toggleDragModeOnDblclick: false,
        viewMode: 1,
        zoomOnTouch: false,
        zoomOnWheel: false
    });

    image.addEventListener('crop', (event: Event) => {
        imageData.x = event.detail.x;
        imageData.y = event.detail.y;
        imageData.width = event.detail.width;
        imageData.height = event.detail.height;
    });
}

function destroyCropper() {
    cropperZoom.value = 0;
    if (cropper) {
        cropper.destroy();
        cropper = false;
    }
}

function zoom() {
    if (cropper)
        cropper.zoomTo(cropperZoom.value / 20);
}

function uploadImage() {
    const formData = new FormData();
    Object.entries(imageData).forEach(([key, value]) => {
        formData.append(key, value);
    });

    if (props.page == "register") {
        http.post('/api/register/profilePicUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((result) => {
            registerStore.setProfilePicture(result);
            errors.value = {};
            closeModal(false);
        })
        .catch((error) => {
            errors.value = error.response.data;
        });
    } else if(props.page == "account") {
        http.post('/api/account/profilePicUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((result) => {
            errors.value = {};
            closeModal(false);
            window.location.reload()
        })
        .catch((error) => {
            errors.value = error.response.data;
        });
    }
}
</script>