<template>
    <button type="button" id="cartButton" @click="cartStore.toggleCart(true)">
        <i class="fa-solid fa-basket-shopping"></i>
        <span class="counter-badge">{{cartStore.item_count}}</span>
    </button>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cart'

const cartStore = useCartStore()
</script>