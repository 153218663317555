<template>
    <div class="posts">
        <div id="carouselSocialGallery" class="posts-carousel">
            <el-skeleton style="width: 300px; margin: 2rem 0 3rem; display: flex; column-gap: 2rem; --el-skeleton-circle-size: 2rem" animated v-if="loading">
                <template #template>
                    <div v-for="index in 4" :key="index">
                        <el-skeleton-item variant="image" style="width: 300px; height: 300px" />
                        <div style="padding: 0.8rem; height: 210px; ">
                            <el-skeleton-item variant="text" />
                            <el-skeleton-item variant="text" />
                            <el-skeleton-item variant="text" />
                            <el-skeleton-item variant="text" />
                            <el-skeleton-item variant="text" />
                            <el-skeleton-item variant="text" />
                        </div>
                        <div style="display: flex; align-items: center; padding: 0.8rem;">
                            <el-skeleton-item variant="circle" style="margin-right: 0.5rem;"/>
                            <el-skeleton-item variant="text" style="width: 60%"/>
                        </div>
                    </div>
                </template>
            </el-skeleton>

            <div class="post" v-for="(post, key) in posts" v-bind:key="post.id" :class="{'active': key == 0}" v-else>
                <div class="content">
                    <div class="image">
                        <a :href="post.permalink" target="_blank">
                            <img :data-src="post.image_url" class="lazyload" alt="Instagram post" height="305" width="305">
                        </a>
                    </div>
                    <div class="text">
                        <p class="comment">{{post.caption.substring(0, 200).concat('...')}}</p>
                        <div class="meta-data">
                            
                            <a class="profile-pic" href="https://www.instagram.com/voxvivarotterdam" target="_blank">
                                <img src="https://voxvivarotterdam.imgix.net/logo.png?auto=compress,format&w=40&h=40&fit=clip" alt="Vox Viva Logo" width="30" height="30">
                            </a>
                            <div class="name-date">
                                <a href="https://www.instagram.com/voxvivarotterdam" target="_blank"><span class="name">voxvivarotterdam</span></a>
                                <span class="date">{{formatUnixDate(post.timestamp, 'D-M-Y')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import http from "../../libs/axios"
import { formatUnixDate } from "@F/js/helpers.ts"

import { tns } from 'tiny-slider/src/tiny-slider'
import LazyLoad from 'vanilla-lazyload'
import type { Ref } from 'vue'

interface Post {
    id: number
    caption: string
    image_url: string
    permalink: string
    timestamp: number
}

const loading = ref(true)
const posts: Ref<Post[]> = ref([])

getPosts()

function getPosts() {
    http.get('/api/home/instaposts')
        .then(response => {
            posts.value = response
            loading.value = false

            setTimeout(() => {
                var slider = tns({
                    container: '#carouselSocialGallery',
                    items: 1,
                    slideBy: 'page',
                    controls: false,
                    navPosition: "bottom",
                    autoplay: false,
                    gutter: 0,
                    responsive: {
                        768: {
                            items: 2
                        },
                        992: {
                            items: 4,
                            nav: false
                        }
                    }
                })

                new LazyLoad({
                    elements_selector: ".lazyload"
                })
            }, 100)
        })
        .catch(error => { return false});
}

</script>

<style lang="scss">
@import "@F/sass/components/_instaposts.scss";
</style>