import { createApp, h } from 'vue'
import {convertDataSet, convertDataSetElement} from './helper'

// import store from '../store'

import MemberList from '../components/MemberList/List.vue'
import ProfileEdit from '../components/Account/ProfileEdit.vue'
import ProfilePictureModal from '../components/Account/ProfilePictureModal.vue'
import ProductAfterPay from '../components/Account/ProductAfterPay.vue'

if(document.getElementById("profileEdit")){
    const app1 = createApp({
        render() {
            return h(ProfileEdit, convertDataSet('profileEdit'))
        }
    })

    app1.mount('#profileEdit')
}

if(document.getElementById("profilePictureModal")){
    const app2 = createApp({
        render() {
            return h(ProfilePictureModal, convertDataSet('profilePictureModal'))
        }
    })

    app2.mount('#profilePictureModal')
}

if(document.getElementById("membersList")){
    const app3 = createApp({
        render() {
            return h(MemberList, convertDataSet('membersList'))
        }
    })

    app3.mount('#membersList')
}

if(document.getElementsByClassName("productAfterpay")){
    const elements = document.getElementsByClassName("productAfterpay");
    for(let i=0; i<elements.length; i++){
        const app4 = createApp({
            render() {
                return h(ProductAfterPay, convertDataSetElement(elements[i]))
            }
        })
        app4.mount(elements[i])
    }
}

// LINK TO TABS
window.addEventListener('DOMContentLoaded', () => {
    const hash = window.location.hash;
    if (hash && hash !== '#') {
        
        const tab = document.getElementById(hash.substring(1)); 
        if (tab) {
            tab.click();
        }
    }
});
