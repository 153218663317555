import { defineStore } from 'pinia'
import http from "../libs/axios"

interface State {
    events_to_pay: any,
}

export const useEventStore = defineStore('event', {
    state: (): State => {
        return {
            events_to_pay: []
        }
    },

    actions: {
        async getPaymentsDue() {
            const response = await http.get('/api/betalingen/openstaand');
            this.events_to_pay = response;
        }
    }
})