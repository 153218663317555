<template>
    <img src="/images/voxy.png" @click="foundVoxy" alt="Voxy">
</template>

<script setup lang="ts">
import http from "../libs/axios"

function foundVoxy() {
    // Put to make it harder to get achievement via just pasting url in browser
    http.put('/api/achievements/voxy')
    .then(() => {
        // Reload page after succesfully found
        window.location.reload();
    })
}
</script>