<template>
    <div class="row products-row">
        <div v-for="product in products" :key="product.id" class="col-md-4 col-sm-6 col-12 product">
            <div class="content" @click="focusProduct(product.id)">
                <div class="thumbnail">
                    <img :src="product.thumbnail" :alt="'Afbeelding ' + product.name" />
                </div>
                <div class="info">
                    <h3 class="title">{{ product.name }}</h3>
                    <div class="description" v-html="product.description"></div>
                    <div class="bottom">
                        <p class="stock">{{ product.stockQuantity }} op voorraad</p>
                        <p class="price">{{ formatCurrency(product.price) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <product-modal v-model:showProductModal="openProductModal" :productId="focussedProductId"></product-modal>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import ProductModal from './ProductModal.vue'

interface Product {
    id: number,
    name: string,
    description: string,
    thumbnail: string,
    price: number,
    stockQuantity: number
}

const props = defineProps({
    'products': Array<Product>
})

const openProductModal = ref(false)
const focussedProductId = ref(0)

function formatCurrency(amount: number) {
    return new Intl.NumberFormat('nl-NL', {style: 'currency',currency: 'EUR'}).format(amount)
}

function focusProduct(product_id: number) {
    focussedProductId.value = product_id
    openProductModal.value = true
}
    
</script>
