<template>
    <div id="map"></div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Loader } from "@googlemaps/js-api-loader"

const props = defineProps({
    'lat': String,
    'lng': String,
    'locationKnown': Boolean
})

const mapCenter = ref({
    lat: 51.907,
    lng: 4.44
})
const markerLocation = ref({
    lat: 51.9,
    lng: 4.48
})
let googleMap = false

onMounted(() => {
    initMap()

    window.addEventListener('resize', calculateCenter)
})

function calculateCenter() {
    mapCenter.value = {
        lat: 51.9,
        lng: 4.48
    }

    markerLocation.value = {
        lat: 51.9,
        lng: 4.48
    }

    if (props.locationKnown) {
        if (props.lat && props.lng) {
            mapCenter.value = {
                lat: parseFloat(props.lat),
                lng: parseFloat(props.lng)
            };

            markerLocation.value = {
                lat: parseFloat(props.lat),
                lng: parseFloat(props.lng)
            }
        }
    }

    if (window.innerWidth < 992) {
        mapCenter.value.lat += 0.003
        mapCenter.value.lng -= 0.005
    } else if (window.innerWidth < 1200) {
        mapCenter.value.lat += 0.003
        mapCenter.value.lng -= 0.02
    } else {
        mapCenter.value.lat += 0.007
        mapCenter.value.lng -= 0.04
    }

    if (googleMap) {
        googleMap.setCenter(new google.maps.LatLng({lat: mapCenter.value.lat, lng: mapCenter.value.lng}))
    }

    setMarker()
}

function setMarker() {
    const image = '/images/marker.png'
    var marker = new google.maps.Marker({
        position: markerLocation.value,
        map: googleMap,
        icon: image
    })

    marker.setMap(googleMap)
}

function initMap() {
    const loader = new Loader({
        apiKey: "AIzaSyBAAhWImY-ZImjZsrMxVgCkR8KZR6J5hww",
        version: "weekly"
    })

    loader.load().then((google) => {
        const mapElement = document.getElementById('map')

        const map = new google.maps.Map(mapElement, {
            zoom: (props.locationKnown ? 14 : 10),
            disableDefaultUI: true,
            gestureHandling: 'none',
            mapId: '444a54f0ee34eeb0'
        })
    
        googleMap = map

        calculateCenter()       
    });           
}
</script>