<template>
    <div class="form-step">
        <div class="row">
            <div class="col-md-6">
                <el-form-item label="Studie" prop="study">
                    <el-autocomplete
                        v-model="study"
                        placeholder="Dit is een verplicht veld"
                        :fetch-suggestions="querySuggestions"
                        @focus="selectedInput='studies'"
                    ></el-autocomplete>
                </el-form-item>
            </div>

            <div class="col-md-6">
                <el-form-item label="Naam instelling" prop="institution">
                    <el-autocomplete
                        v-model="institution"
                        placeholder="Dit is een verplicht veld"
                        :fetch-suggestions="querySuggestions"
                        @focus="selectedInput='institutions'"
                    ></el-autocomplete>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <el-form-item label="Studieplaats" prop="study_site">
                    <el-autocomplete
                        v-model="study_site"
                        placeholder="Dit is een verplicht veld"
                        :fetch-suggestions="querySuggestions"
                        @focus="selectedInput='studysites'"
                    ></el-autocomplete>
                </el-form-item>
            </div>

            <div class="col-md-6">
                <el-form-item label="Startjaar studie" prop="study_start">
                    <el-input v-model.number="study_start" placeholder="Dit is een verplicht veld"></el-input>
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRegisterStore } from '../../stores/register'
import { storeToRefs } from 'pinia'
import http from "../../libs/axios"

const registerStore = useRegisterStore()
const { study, institution, study_site, study_start } = storeToRefs(registerStore)
const selectedInput = ref('')

const querySuggestions = (queryString: string, cb: (arg: any) => void) => {
    http.post('/api/register/querySuggestions/' + selectedInput.value, {'query': queryString})
    .then(response => {
        cb(response);
    })
    .catch(() => {cb([])});
}
</script>