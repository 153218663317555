import { createApp, h } from 'vue'
import {convertDataSet} from './helper'

import Overview from '../components/Shop/Overview.vue'
import Cart from '../components/Shop/Cart.vue'
import CartButton from '../components/Shop/CartButton.vue'

if(document.getElementById("shopOverview")){
    const app1 = createApp({
        render() {
            return h(Overview, convertDataSet('shopOverview'))
        }
    })

    app1.mount('#shopOverview')
}

if(document.getElementById("cartDrawer")){
    const app2 = createApp({
        render() {
            return h(Cart, convertDataSet('cartDrawer'))
        }
    })

    app2.mount('#cartDrawer')
}

if(document.getElementById("cartButton")){
    const app3 = createApp({
        render() {
            return h(CartButton)
        }
    })

    app3.mount('#cartButton')
}
