//@ts-ignore
import moment from 'moment/min/moment-with-locales'

export function removePhoneNumberPrefix(number: undefined | string, add_landcode: boolean = false) {
    if (!number) {
        return null
    }

    // Remove 06 from phonenumber
    let cut_off_number = number.replace(/^(06)/, "");

    // Remove dash from phonenumber
    cut_off_number = cut_off_number.replace("-", "");
    // Remove spaces
    cut_off_number = cut_off_number.replace(" ", "");

    // Add 316 to phonenumber
    if (add_landcode) {
        cut_off_number = "316" + cut_off_number;
    }

    return parseInt(cut_off_number);
}

export function formatUnixDate(timestamp: number, format: string = 'DD-MM-YYYY') {
    moment.locale('nl')
    return moment.unix(timestamp).format(format);
}

export function formatDate(timestamp: string, format: string = 'DD-MM-YYYY') {
    moment.locale('nl')
    return moment(timestamp).format(format);
}