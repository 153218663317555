<template>
    <div class="member col-xl-3 col-lg-4 col-sm-6 col-12">
        <div class="content" v-if="member">
            <a href="javascript:void(0)" @click="focusMember()">
                <div class="profile-picture">
                    <img :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/' + member.profile_pic" v-if="member.profile_pic">
                    <img src="/images/profile-pic/profile-person.jpg" v-else>
                </div>
                <div class="info">
                    <h4 class="name">{{member.name}}</h4>

                    <div class="details">
                        <span class="detail"><i class="fa-solid fa-baby icon"></i>{{formatUnixDate(member.birthdate)}}</span>
                        <span class="detail"><i class="fa-solid fa-phone icon"></i>{{member.phone}}</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { formatUnixDate } from "@F/js/helpers.ts"

interface Member {
    id: number,
    name: string,
    profile_pic: string,
    birthdate: number,
    phone: string
}

const props = defineProps({
    'member': Object as PropType<Member>
})
const emit = defineEmits(['member-focussed'])

function focusMember() {
    if (props.member) {
        emit('member-focussed', props.member.id);
    }
}
</script>
