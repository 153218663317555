<template>
    <button  role="button" class="btn btn-danger" @click="payPurchase()" :disabled="loading">Betalen</button>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import http from '../../libs/axios'

const props = defineProps({
    'purchase_id': Number,
})

const loading = ref(false)

function payPurchase() {
    loading.value = true
    http.get('/api/producten/betalen/' + props.purchase_id)
    .then((checkoutUrl) => {
        if (typeof checkoutUrl === 'string') {
            window.location.assign(checkoutUrl)
        }
    })
    .catch(() => {
        loading.value = false
        return false
    })
}
</script>